<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="750"
  >
    <v-card :loading="isLoading">
      <v-card-title>
        <v-row>
          <v-col class="d-flex justify-end">
            <v-toolbar-items>
              <v-btn
                icon
                color="black"
                dark
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="mt-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12" sm="3">
              <label>Shipper</label>
              <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
            </v-col>
            <v-col cols="12" sm="9">
              <common-auto-complete-items
                type="masterShipper"
                searchName="companyName"
                item-value="id"
                item-text="companyName"
                v-model="form.companyId"
                dense
                outlined
                class="caption"
                clearable
                placeholder="Pilih Shipper"
                :rules="[v => !!v || $_strings.messages.validation.REQUIRED('Pilih Shipper')]"
                :filter="filterShipper"
                :items="shipperItems"
                :optional="{
                  status: 'active',
                  isGroup: true,
                  isOracleIntegrated: true,
                }"
                @updateItems="updateShipperItems"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="3">
              <label>File</label>
              <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
            </v-col>
            <v-col cols="12" sm="9">
              <v-file-input
                outlined
                dense
                v-model="form.file"
                :rules="[v => !!v || $_strings.messages.validation.REQUIRED('File')]"
                prepend-icon=""
                class="caption"
                placeholder="Pilih File"
                accept=".csv"
                truncate-length="15"
                @change="onChangeFileInput"
              ></v-file-input>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-btn
              outlined
              class="mr-2 ml-auto"
              @click="dialog = false"
            >
              {{$_strings.common.CANCEL}}
            </v-btn>
            <v-btn
              depressed
              color="primary"
              class="white--text"
              @click="submit"
              :loading="isLoading"
            >
              {{$_strings.common.SAVE}}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  watch: {
    dialog(newVal) {
      if (!newVal) {
        this.form = {
          companyId: null,
          file: null,
        };
      }
    },
  },
  data() {
    return {
      isLoading: false,
      dialog: false,
      valid: false,
      shipperItems: [],
      filterShipper: {
        search: '',
        page: 0,
        size: 25,
        totalData: 0,
      },
      form: {
        companyId: null,
        file: null,
      },
    };
  },
  methods: {
    updateShipperItems(items) {
      this.shipperItems = [...this.shipperItems, ...items];
    },
    onChangeFileInput(event) {
      if (!event) return;
      const { type } = event;
      const fileTypeSupport = ['text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];
      if (!fileTypeSupport.includes(type)) {
        this.form.file = undefined;
        this.$dialog.notify.error('Format file tidak didukung');
        return false;
      }
      return true;
    },
    async submit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      const formData = new FormData();
      formData.append('file', this.form.file);
      formData.append('type', this.form.file.type);
      try {
        this.isLoading = true;
        await this.$_services.oracleData.uploadLocationOracle({ formData, companyId: this.form.companyId });
        this.$dialog.notify.success('Berhasil Upload File');
        this.dialog = false;
        this.$emit('fetchData');
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
